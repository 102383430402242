<template>

  <!-- Start Content-->
  <div>
    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="!jsonErr && !checkKeys.length">
      <!-- 表示項目無し -->
      <code>
        表示項目が選択されていません。
      </code>
    </div>
    <div class="responsive-table-plugin" v-else-if="!jsonErr">

      <div class="table-rep-plugin">
        <div class="table-responsive">
          <table class="table table-striped focus-on" style="width:100%">
            <thead>
              <tr>
                <th style="width:40px;min-width:0 !important;">ID</th>
                <th>会社名</th>
           
              </tr>
            </thead>
            <tbody v-if="jsonObj.count && checkKeys.length">
              <tr v-for="(obj, idNum) in listData" :key="idNum" :class="{ 'focused' : isFocus === idNum }" @click="toggleFocus(idNum)" @child="toggleFocus(idNum)">
                <td style="width:40px;min-width:0 !important;">{{idNum+1}}</td>
                <td><router-link :to="'/corpRecommend?cur_tab=tab2&dataId='+(obj[viewParams.articleParamId]!=undefined ? obj[viewParams.articleParamId]:0)+'&dataType=100&dataName='+(obj.Corp_Name!=undefined ?obj.Corp_Name:obj.corpname)+'&dataNumber='+(obj.corp_num!=undefined ?obj.corp_num:obj.Corp_Num)+'&code='+(obj.code!=undefined ?obj.code:'')+'&hideHead=1&curindex=tc1'" class="table-data-container" >{{(obj.Corp_Name!=undefined ? obj.Corp_Name:obj.corpname)}}</router-link></td>
              </tr>
            </tbody>
          </table>
          <div class="p-2">
            <code v-if="!jsonObj.total_count">表示データがありません。</code>
          </div>
        </div> <!-- end .table-responsive -->
      </div> <!-- end .table-rep-plugin-->

      
    </div> <!-- end .responsive-table-plugin-->
    <div v-else>
      <code>
      データ取得に失敗しました。
      </code>
    </div>
      
  </div> <!-- container -->

</template>

<script>

import axios from 'axios';

export default {
  name: 'ListTable',
  props: {
    viewParams: {
      name: {
        type: String,
        default: ''
      },
      apiUrl: {
        type: String,
        default: '',
      },
      dataType: {
        type: String,
        default: 1,
      },
      articleView: String,
      articleParamId: String
    },
  },
 
  data() {
    return {
      offset: 0,
      listStrage: {
        query: '',
        limit:100,
        paged: 1,
      },
      maxPage: 0,
      checkKeys: [],
      jsonObj: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object]
      },
      tableHeaders: [],
      listData: [Object],
      isFocus: -1,
      ddMenu: false,
      jsonErr: false,
      isLoading: true,
      downloadAlert: '',
    }
  },
  mounted() {
    if( localStorage.getItem(this.viewParams.name + '_limit') ) {
      this.listStrage.limit = localStorage.getItem(this.viewParams.name + '_limit')
    }
    if( sessionStorage.getItem(this.viewParams.name + '_query') ) {
      this.listStrage.query = sessionStorage.getItem(this.viewParams.name + '_query')
    }
    if( sessionStorage.getItem(this.viewParams.name + '_paged') ) {
      this.listStrage.paged = Number(sessionStorage.getItem(this.viewParams.name + '_paged'))
    }
    if( localStorage.getItem(this.viewParams.name + '_tableHeaders') ) {
      //読み込み時点のlocalstrageのtableheadersを取得
      let localTableHeaders = JSON.parse(localStorage.getItem(this.viewParams.name + '_tableHeaders'))

      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: (this.listStrage.paged - 1) * this.listStrage.limit, // 開始位置
            limit: this.listStrage.limit, //表示件数
            q: this.listStrage.query //キーワード
          }
        })
        .then(response => {
            this.jsonObj = response.data
            this.tableHeaders = this.jsonObj.headers
            this.listData = this.jsonObj.data
            this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit)
            this.checkKeys = JSON.parse(localStorage.getItem(this.viewParams.name + '_checked'))
            
            // localstrageにないtableheaderをチェック済リストに追加
            let addTableHeaders = this.tableHeaders.filter(item => localTableHeaders.indexOf(item) == -1)
            if( addTableHeaders.length ) {
              this.checkKeys.push.apply(this.checkKeys, addTableHeaders)
            }

            // tableheaderから無くなったheaderkeyがチェック済リストにあれば削除
            let delTableHeaders = this.checkKeys.filter(item => this.tableHeaders.indexOf(item) == -1)
            if( delTableHeaders.length ) {
              let filterVal = this.checkKeys.filter(item => delTableHeaders.indexOf(item) == -1)
              this.checkKeys = filterVal
            }
            
            // APIで取得した新しいtableheadersをlocalstrageに保存
            localStorage.setItem(this.viewParams.name + '_tableHeaders', JSON.stringify(this.tableHeaders))
            
            this.isLoading = false
          }
        )
        .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          }
        );
    } else {
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: (this.listStrage.paged - 1) * this.listStrage.limit, // 開始位置
            limit: this.listStrage.limit, //表示件数
            q: this.listStrage.query //キーワード
          }
        })
        .then(response => (
            this.jsonObj = response.data,
            this.tableHeaders = this.jsonObj.headers,
            this.listData = response.data.data,
            this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
            this.checkKeys = Object.keys(this.listData[0]),
            localStorage.setItem(this.viewParams.name + '_tableHeaders', JSON.stringify(this.tableHeaders)),
            this.isLoading = false
          )
        )
        .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          }
        );
    }
  },
  methods: {
    toggleDDMenu: function() {
      this.ddMenu = !this.ddMenu
    },
    allCheck: function() {
      this.checkKeys = this.tableHeaders
    },
    allUncheck: function() {
      this.checkKeys = []
    },
    toggleFocus: function(idNum) {
      this.isFocus = idNum;
    },
    search: function() {
      this.isLoading = true
      localStorage.setItem(this.viewParams.name + '_limit', this.listStrage.limit)
      sessionStorage.setItem(this.viewParams.name + '_query', this.listStrage.query)
      sessionStorage.setItem(this.viewParams.name + '_paged', 1)
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: 0, //開始位置
            limit: this.listStrage.limit, //表示件数
            q: this.listStrage.query //キーワード
          }
        })
        .then(response => (
            this.jsonObj = response.data,
            this.tableHeaders = this.jsonObj.headers,
            this.listData = this.jsonObj.data,
            this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
            this.listStrage.paged = 1,
            this.isLoading = false
          )
        )
        .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          }
        );
    },
    pagenateNum: function(num) {
      this.isLoading = true
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: (num - 1) * this.listStrage.limit, //開始位置
            limit: this.listStrage.limit, //表示件数
            q: this.listStrage.query //キーワード
          }
        })
        .then(response => (
            this.jsonObj = response.data,
            this.tableHeaders = this.jsonObj.headers,
            this.listData = this.jsonObj.data,
            this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
            this.listStrage.paged = Math.round(this.jsonObj.offset / this.listStrage.limit) + 1,
            sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged),
            this.isLoading = false
          )
        )
        .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          }
        );
    },
    pagenateKey: function(key) {
      switch(key) {
        case 'next':
          if( this.listStrage.paged < this.maxPage ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * this.listStrage.paged
          }
          break
        case 'prev':
          if( this.listStrage.paged > 1 ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * (this.listStrage.paged - 2)
          }
          break
        case 'first':
          if( this.listStrage.paged > 1 ) {
            this.isLoading = true
            this.offset = 0
          }
          break
        case 'last':
          if( this.listStrage.paged < this.maxPage ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * (this.maxPage - 1)
          }
          break
        default:
          this.isLoading = false
          console.log('Non-existent key')
      }
      if(this.isLoading) {
        axios
          .get(this.viewParams.apiUrl, {
            params: {
              offset: this.offset, //開始位置
              limit: this.listStrage.limit, //表示件数
              q: this.listStrage.query //キーワード
            }
          })
          .then(response => (
              this.jsonObj = response.data,
              this.tableHeaders = this.jsonObj.headers,
              this.listData = this.jsonObj.data,
              this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
              this.listStrage.paged = Math.round(this.jsonObj.offset / this.listStrage.limit) + 1,
              sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged),
              this.isLoading = false
            )
          )
          .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
            }
          );
      }
    },
    pageHide: function(num) {
      if( num < this.listStrage.paged - 4 || num > this.listStrage.paged + 4 ) {
        return true
      }
    },
    listDownload: function() {
      let xmldata
      axios
        .post(this.viewParams.apiUrl + 'download', 
        {
          data: this.listData,
          items: this.checkKeys
        },
        {
          responseType: 'blob'
        }
        )
        .then(response => {
            xmldata = response.data
            this.$nextTick(() => {
              let blob = new Blob([xmldata])
              let link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = 'corps.xlsx'
              link.click()
            })
            this.downloadAlert = ''
            this.isLoading = false
          }
        )
        .catch(error => {
            this.downloadAlert = 'ダウンロードに失敗しました。'
            console.log(error)
            this.isLoading = false
          }
        );
      
    }
  },
  watch: {
    checkKeys(newCheckKeys) {
      localStorage.setItem(this.viewParams.name + '_checked', JSON.stringify(newCheckKeys));
    },
    isLoading: function() {
      if(!this.isLoading) {
        this.$nextTick(() => {
          window.parent.postMessage(['h'], "*")
        })
      } else {
        //ローディング開始のタイミングでisFocusをリセット
        this.isFocus = -1
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.form-inline {
  flex-wrap: nowrap;
}

.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {

  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: .75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: .5em;
    width: 100%;
  }

}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: .15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -.5rem;
  top: -.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}

.dropdown-menu.show li {
  flex: 0 1 32.5%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

@media screen and (max-width: 768px) {

  .dropdown-menu.show li {
    flex-basis: 50%;
  }

}

@media screen and (max-width: 500px) {

  .dropdown-menu.show li {
    flex-basis: 100%;
  }

}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover, table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  background: #fff;
  box-shadow: 0 -.1rem #dee2e6 inset;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table.table-striped td {
    padding: 3px;
  white-space: normal !important;
  word-wrap: break-word;
}

.table td, .table th{
  padding: 3px !important;
}
.table.table-striped .table-focus-on-td {

  align-items: center;
  min-width: auto;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: .15s;
}

// transition
.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}
.table-data-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
}
.focused a{color:#fff !important;}
</style>