<template>
  <div>
    <headers />
    <div class="mb row" style="padding:15px;padding-top: 70px !important;">
      <div class="col-12">
        <div class="page-title-box">
          <a  :href="'/#/corpRecommend?cur_tab=tab1&data_title=TOP10企業一覧'" class="page-title" style="line-height:50px;font-size:20px">TOP100企業一覧</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="">
          <list-table2 :viewParams="viewParams" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable2 from '@/components/ListTable2.vue'
import Headers from '@/components/Headers.vue'

export default {
  name: 'Corptop100',
  components: {
    ListTable2,
    Headers
  },
  data() {
    return {
      viewParams: {
        name: 'corp1',
        apiUrl: '/v1/corps1/',
        articleView: 'CorpArticle1',
        articleParamId: 'ID',
        dataType:1,
        limit:100,
      }
    }
  }
}
</script>